// @flow

import { graphql } from "gatsby";
import React, { useState, useEffect } from "react";
import {
    Lightbox,
    PropertyMainInformation,
    Banner,
    Room,
    Shower,
    PropertyImageCarousel,
    PropertyVisualInformation,
    UspTiles,
} from "@2po-c21/components";

import { PageContent, Section, PropertyDescription } from "@containers";
import {
    PropertyDetailsBreadcrumb,
    PropertyHeader,
    PropertyLocation,
    AgencyContactForm,
    ProjectDetailsMeta,
    ProjectDetailsProjectPropertiesDisplay as ProjectPropertiesDisplay,
} from "@components";
import { Col, Row } from "reactstrap";
import { gettext, ngettext, msgid, t, addLocale, useLocale } from "ttag";

import Image from "gatsby-image";
import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";
import { EVENT_MAP, type PageImpressionParams } from "../models/events";
import { TrackPageImpression } from "../components/Tracker/Tracker";
const ProjectDetailsPageTemplate = ({
    data: {
        project,
        contentfulNavigation: { navigationItems },
        carouselFallbackImage: {
            childImageSharp: { fluid },
        },
        c21Translation: { translations },
        agencyFallbackImage,
        footerItems,
    },
    pageContext: { translationSlugs, locale, country },
}: *) => {
    const {
        api_id,
        title,
        address,
        price,
        rooms,
        description,
        agent,
        agency,
        carouselImages,
        visualImages,
    } = project;

    addLocale(locale, translations);
    useLocale(locale);

    const [hasMounted, setHasMounted] = useState(false);
    const [lightboxState, setLightboxState] = useState({
        isOpen: false,
        activeContent: null,
    });
    const gtmDispatcher = useGTMDispatch();
    useEffect(() => {
        const params: PageImpressionParams = {
            agency: agency
                ? {
                      id: agency.api_id,
                      name: agency.name,
                      postalcode: agency.address.postalCode,
                      city: agency.address.city,
                      country: agency.address.country,
                  }
                : null,
            agencypage: "false",
            language: locale,
            pagename: "Project details",
            typetemplate: "project-details",
        };
        TrackPageImpression(gtmDispatcher, EVENT_MAP.PageImpression, params);
    }, []);
    useEffect(() => {
        setHasMounted(true);
    }, []);

    const toFluidImages = (images) =>
        images &&
        images
            .map((i) => ({ ...i, aspectRatio: +i.aspectRatio }))
            .map((i) => ({
                description: i.description,
                // eslint-disable-next-line react/display-name
                imageSrc: () => (
                    <Image
                        fluid={i.fluid}
                        alt={i.description}
                        backgroundColor={true}
                    />
                ),
            }));

    const toFixedImages = (images) =>
        images &&
        images.map((i) => ({
            description: i.description,
            // eslint-disable-next-line react/display-name
            imageSrc: () => (
                <Image
                    fixed={i.fixed}
                    alt={i.description}
                    backgroundColor={true}
                />
            ),
        }));

    const projectImageCarouselContent = (activeIndex) => {
        return (
            <PropertyImageCarousel
                images={toFluidImages(carouselImages)}
                largeImage
                reactToArrowKeys={true}
                initialIndex={activeIndex}
                disableReset={true}
            />
        );
    };

    const projectVisualInformationContent = (activeIndex) => {
        return (
            <Image
                fluid={visualImages[activeIndex]?.fluid}
                alt={visualImages[activeIndex]?.description}
                backgroundColor={true}
            />
        );
    };

    const openLightbox = (contentSource) => {
        setLightboxState({
            ...lightboxState,
            isOpen: true,
            activeContent: contentSource(),
        });
    };

    const fallbackImage = (
        <Image fluid={fluid} alt={t`common.text.fallback-image`} />
    );

    return (
        <PageContent
            locale={locale}
            navigationItems={navigationItems}
            translationSlugs={translationSlugs}
            footerItems={footerItems}
            Breadcrumb={
                <PropertyDetailsBreadcrumb
                    locale={locale}
                    propertyTitle={project.title}
                />
            }
            country={country}
        >
            <ProjectDetailsMeta
                project={project}
                translationSlugs={translationSlugs}
                locale={locale}
            />
            {hasMounted && (
                <Lightbox
                    isOpen={lightboxState.isOpen}
                    contentSource={() => lightboxState.activeContent}
                    onClickOutside={() =>
                        setLightboxState({
                            ...lightboxState,
                            isOpen: false,
                        })
                    }
                />
            )}
            <Section>
                <PropertyHeader>
                    <Row>
                        <Col xl={{ size: 7, order: 1 }}>
                            {carouselImages.length > 0 ? (
                                <PropertyImageCarousel
                                    images={toFluidImages(carouselImages)}
                                    reactToArrowKeys={!lightboxState.isOpen}
                                    onClick={(key) =>
                                        openLightbox(() =>
                                            projectImageCarouselContent(key),
                                        )
                                    }
                                />
                            ) : (
                                fallbackImage
                            )}
                        </Col>
                        <Col xl={{ size: 5, order: 0 }}>
                            <PropertyMainInformation
                                title={title}
                                address={address}
                                addressHiddenText={gettext(
                                    "common.address.on-demand",
                                )}
                                price={price}
                            />
                        </Col>
                    </Row>
                </PropertyHeader>
            </Section>
            <Banner>
                <Banner.Item
                    icon={Room}
                    iconSize={"xl"}
                    type={ngettext(
                        msgid`property-details.banner-icon.bedroom`,
                        `property-details.banner-icon.bedrooms`,
                        rooms.numberOfBedrooms || 0,
                    )}
                    value={rooms.numberOfBedrooms}
                />
                <Banner.Item
                    icon={Shower}
                    iconSize={"xl"}
                    type={ngettext(
                        msgid`property-details.banner-icon.bathroom`,
                        `property-details.banner-icon.bathrooms`,
                        rooms.numberOfBathrooms || 0,
                    )}
                    value={rooms.numberOfBathrooms}
                />
            </Banner>
            <Section>
                <PropertyDescription
                    propertyId={project?.id}
                    title={t`property-details.description.title`}
                    subTitle={t`property-details.description.sub-title`}
                    description={description}
                    agent={agent}
                    agency={agency}
                    fallbackImage={agencyFallbackImage.childImageSharp}
                    contactFormAnchor={AgencyContactForm.anchor}
                />
            </Section>
            <Section>
                <ProjectPropertiesDisplay property={{ id: api_id, project }} />
            </Section>
            {visualImages.length > 0 ? (
                <Section theme={"themeGray"}>
                    <PropertyVisualInformation
                        title={t`property-details.visual-information.title`}
                        images={toFixedImages(visualImages)}
                        onClick={(key) =>
                            openLightbox(() =>
                                projectVisualInformationContent(key),
                            )
                        }
                    />
                </Section>
            ) : undefined}
            {project && !project?.address?.hidden && project.location && (
                <Section theme={"themeSecondary"} enableLogo>
                    <PropertyLocation property={project} />
                </Section>
            )}
            <AgencyContactForm
                agencyApiId={agency.api_id}
                locale={locale}
                type={"property"}
            />
            {renderUsp()}
        </PageContent>
    );
};

const renderUsp = () => (
    <UspTiles
        tiles={[
            { data: t`usp.network.title`, label: t`usp.network.subtitle` },
            { data: t`usp.sales.title`, label: t`usp.sales.subtitle` },
            {
                data: t`usp.relationships.title`,
                label: t`usp.relationships.subtitle`,
            },
        ]}
    />
);

export default ProjectDetailsPageTemplate;

export const query = graphql`
    query ($id: String!, $locale: String!, $country: String!) {
        project: c21Project(id: { eq: $id }, locale: { eq: $locale }) {
            api_id
            title
            surface {
                habitableSurfaceArea {
                    unit
                    value
                }
            }
            rooms {
                numberOfBathrooms
                numberOfBedrooms
            }
            properties {
                id
                type
                subType
                address {
                    street
                    number
                    box
                    postalCode
                    city
                    country
                }
                surface {
                    habitableSurfaceArea {
                        unit
                        value
                    }
                    surfaceAreaGarden {
                        unit
                        value
                    }
                }
                images {
                    description
                    fixed(width: 310, height: 177) {
                        width
                        height
                        src
                        srcSet
                        srcWebp
                        srcSetWebp
                    }
                }
                rooms {
                    numberOfBathrooms
                    numberOfBedrooms
                }
                price
                energySpecifications {
                    energyLabel
                    energyScore {
                        unit
                        value
                    }
                    energyReportReference
                    energySources {
                        name
                    }
                    totalEnergyConsumption {
                        unit
                        value
                    }
                    co2Emission {
                        unit
                        value
                    }
                }
                slug
                numberOfFacades
                condition
                hasParking
            }
            price
            location {
                latitude
                longitude
            }
            api_id
            agency {
                api_id
                slug
                name
                address {
                    city
                    countryCode
                    postalCode
                    street
                    number
                }
                images {
                    description
                    fixed(width: 100, height: 100) {
                        width
                        height
                        src
                        srcSet
                        srcWebp
                        srcSetWebp
                    }
                }
            }
            agent {
                firstName
                lastName
                images {
                    description
                    fixed(width: 100, height: 100) {
                        width
                        height
                        src
                        srcSet
                        srcWebp
                        srcSetWebp
                    }
                }
            }
            address {
                city
                countryCode
                postalCode
                street
                number
                hidden
            }
            visualImages: images {
                description
                fixed(width: 270, height: 160) {
                    width
                    height
                    src
                    srcSet
                    srcWebp
                    srcSetWebp
                }
                fluid(maxWidth: 1134) {
                    aspectRatio
                    sizes
                    src
                    srcSet
                    srcWebp
                    srcSetWebp
                }
            }
            carouselImages: images {
                description
                fixed(width: 670, height: 384) {
                    width
                    height
                    src
                    srcSet
                    srcWebp
                    srcSetWebp
                }
                fluid(maxWidth: 1134) {
                    aspectRatio
                    sizes
                    src
                    srcSet
                    srcWebp
                    srcSetWebp
                }
            }
        }
        carouselFallbackImage: file(relativePath: { eq: "no-image.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 670, quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        agencyFallbackImage: file(relativePath: { eq: "fallback.png" }) {
            childImageSharp {
                fixed(width: 100, height: 100) {
                    ...GatsbyImageSharpFixed
                }
            }
        }
        contentfulNavigation(
            title: { eq: "Main Navigation" }
            node_locale: { eq: $locale }
            country: { eq: $country }
        ) {
            ...ContentfulNavigation
        }
        c21Translation(locale: { eq: $locale }) {
            translations(
                keys: [
                    "project-details.*"
                    "property-details.*"
                    "common.text.read-more"
                    "common.text.show-more"
                    "common.text.and"
                    "common.text.yes"
                    "common.text.no"
                    "common.text.maybe"
                    "common.text.language"
                    "common.address.on-demand"
                    "common.year"
                    "common.gas"
                    "common.electricity"
                    "common.heat-pump"
                    "common.fuel"
                    "usp.*"
                    "api.*"
                    "form.agency-contact.*"
                    "slug.property-detail"
                    "slug.privacy-policy"
                    "favourites.*"
                    "title.project-details *"
                    "breadcrumbs.property*"
                    "agency.header.button.text"
                    "slug.estimation-tool"
                ]
            )
        }
        footerItems: contentfulNavigation(
            title: { eq: "Footer" }
            node_locale: { eq: $locale }
            country: { eq: $country }
        ) {
            ...ContentfulNavigation
        }
    }
`;
